import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Container, Row, Col } from 'react-grid-system'

import SvgColumns from './svg-columns'
import Waves from './waves'

export default function Intro(props) {
    return (
        <div className="onecol-container py-56">
            <Container>
                <Row>
                    <Col md={8} sm={12} className="mx-auto text-center">
                        <h5 className="text-orange-300">{props.subtitle}</h5>
                        <h2 className="text-green-300">{props.title}</h2>
                        <ReactMarkdown children={props.description} />
                        {props.home ? 
                            <SvgColumns />
                        : null }
                    </Col>
                </Row>
            </Container>  
            {!props.blog ?
                <Waves /> // <== Wave Animation
            : null }
        </div>
    )
}