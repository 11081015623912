import React from 'react'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import VideoBg from "reactjs-videobg"

import mp4 from '../../../images/video/rf-kiln.mp4'
import webm from '../../../images/video/rf-kiln.webm'
import poster from '../../../images/video/poster.png'

export default function Hero(props) {

    return (
        <>
            {!props.video ?
            <div className="banner-row flex items-center" style={{backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${props.banner})`}}>
                <Container className="w-full">
                    <Row>
                        {!props.blog ?
                        <>
                            <Col md={9} sm={12} className="hero mx-auto">
                                <h1 className="text-center font-light white-text">{props.title}</h1>
                            </Col>
                        </>
                        :
                            null
                        }
                    </Row>
                </Container>
            </div>
            :
            <>
            <Visible xs sm md lg>
                <div className="banner-row flex items-center" style={{backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${poster})`}}>
                    <Container className="w-full">
                        <Row>
                            <Col md={9} sm={12} className="hero mx-auto">
                                <h1 className="text-center font-light white-text">Our Kilns</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Visible>
            <Hidden xs sm md lg>
                <VideoBg autoPlay wrapperClass="video-banner" poster={poster}>
                    <VideoBg.Source src={webm} type="video/webm" />
                    <VideoBg.Source src={mp4} type="video/mp4" />
                </VideoBg>
            </Hidden>
            </>
            }
        </>
    )
}
