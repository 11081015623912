import React from 'react'
import './animation.css'

export default function Waves() {
    return (
        <>
        <svg width="0" height="0" version="1.1" className="radio-parent">
            <defs>
            <symbol id="radio-wave" viewBox="0 0 100 200">
                <title>radio wave</title>
                <g>
                <path d="M62.5,185 Q12.5,100 62.5,15" />
                </g>
            </symbol>
            <symbol id="radio-source" viewBox="0 0 100 100">
                <title>radio source</title>
                <g>
                <circle cx="50" cy="50" r="50" />
                </g>
            </symbol>
            </defs>
        </svg>
        <div className="radio-wave-container">
            <div className="radio-wave-container content">
            <svg className="radio-source">
                <use xlinkHref="#radio-source"></use>
            </svg>
            <svg className="radio-wave radio-wave-1">
                <use xlinkHref="#radio-wave"></use>
            </svg>
            <svg className="radio-wave radio-wave-2">
                <use xlinkHref="#radio-wave"></use>
            </svg>
            <svg className="radio-wave radio-wave-3">
                <use xlinkHref="#radio-wave"></use>
            </svg>
            <svg className="radio-wave radio-wave-4">
                <use xlinkHref="#radio-wave"></use>
            </svg>
            <svg className="radio-wave radio-wave-5">
                <use xlinkHref="#radio-wave"></use>
            </svg>
            <svg className="radio-wave radio-wave-6">
                <use xlinkHref="#radio-wave"></use>
            </svg>
            </div>
        </div> 
      </> 
    )
}
