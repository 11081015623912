import React from "react"

export default function SvgColumns() {
  return (
    <div className="svg-home-wrap flex flex-wrap mt-24">
      <div className="md:w-1/3 inline-flex items-center flex-col px-10 mb-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-box"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#548272"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
          <line x1="12" y1="12" x2="20" y2="7.5" />
          <line x1="12" y1="12" x2="12" y2="21" />
          <line x1="12" y1="12" x2="4" y2="7.5" />
        </svg>
        <h3 className="text-green-300">Saves Time</h3>
        <p>
          Various kiln sizes to meet production needs, plus a significant reduction of up to 1/10th drying time!
        </p>
      </div>
      <div className="md:w-1/3 inline-flex items-center flex-col px-10 mb-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-bug"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#548272"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 9v-1a3 3 0 0 1 6 0v1" />
          <path d="M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1 -10 0v-3a6 6 0 0 1 1 -3" />
          <line x1="3" y1="13" x2="7" y2="13" />
          <line x1="17" y1="13" x2="21" y2="13" />
          <line x1="12" y1="20" x2="12" y2="14" />
          <line x1="4" y1="19" x2="7.35" y2="17" />
          <line x1="20" y1="19" x2="16.65" y2="17" />
          <line x1="4" y1="7" x2="7.75" y2="9.4" />
          <line x1="20" y1="7" x2="16.25" y2="9.4" />
        </svg>
        <h3 className="text-green-300">Pest Control</h3>
        <p>
          Effective heat treatment against invasive forest pests like the
          emerald ash borer and long-horned pine beetle.
        </p>
      </div>
      <div className="md:w-1/3 inline-flex items-center flex-col px-10 mb-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-christmas-tree"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#548272"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 3l4 4l-2 1l4 4l-3 1l4 4h-14l4 -4l-3 -1l4 -4l-2 -1z" />
          <path d="M14 17v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-3" />
        </svg>
        <h3 className="text-green-300">Fewer Defects</h3>
        <p>
          Reduction in defects such as cracks or warping results in the highest quality wood products.
        </p>
      </div>
    </div>
  )
}
